<template>
  <div
    id="GoTop"
    v-if="btnFlag"
    @click="GoTop()"
    style="width: 40px; height: 40px"
  >
    <!-- <span class="glyphicon glyphicon-chevron-up"></span> -->
    <img class="glyphicon" src="../assets/images/top.png" alt="" />
  </div>
</template>
<script>
export default {
  name: 'GoTop',
  data() {
    return {
      btnFlag: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop)
  },
  methods: {
    GoTop() {
      const that = this
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    scrollToTop() {
      const that = this
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 0) {
        that.btnFlag = true
      } else {
        that.btnFlag = false
      }
    }
  }
}
</script>
<style scoped>
#GoTop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 99;
  cursor: pointer;
  border-radius: 50%;
}
.glyphicon {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  color: rgb(8, 162, 233);
  font-size: 30px;
}
</style>
