<template>
  <div class="footer">
    <div class="footer-logo">
      <img src="@/assets/images/footer/footer-logo.png" alt="" />
    </div>
    <div class="footer-content footer-marginTop35">
      <img
        src="@/assets/images/footer/footer-address.png"
        alt=""
        style="margin-right: 8px"
      />
      <span>北京市大兴区亦庄经济开发区地盛东路1号爱普益大厦3号楼爱普空间501室</span>
    </div>
    <div class="footer-content footer-marginTop8">
      <img
        src="@/assets/images/footer/footer-phone.png"
        alt=""
        style="margin-right: 8px"
      />
      <span>18610331501</span>
    </div>
    <div class="footer-content footer-marginTop8">
      <img
        src="@/assets/images/footer/footer-email.png"
        alt
        style="margin-right: 8px"
      />
      <span>info@lh-zk.com</span>
    </div>
    <div class="footer-copyright footer-marginTop35">
      © 2021 - 2024 领航中科（北京）科技有限公司
    </div>
    <div class="footer-copyright footer-marginTop8">
      <a class="record" href="https://beian.miit.gov.cn" target="_blank">
        京ICP备2022019605号-6
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss">
.footer {
  width: 100%;
  // height: 412px;
  background-color: #f5f5f5;
  color: #fff;
  text-align: center;
  line-height: 100px;
  background-image: url('@/assets/images/footer/footerBg.png');
  padding: 56px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .footer-logo {
    width: 237px;
    height: 56px;
    img {
      width: 237px;
      height: 56px;
    }
  }
  .footer-marginTop35 {
    margin-top: 35px;
  }
  .footer-marginTop8 {
    margin-top: 8px;
  }

  .footer-content {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    line-height: 26px;
    letter-spacing: 7px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    display: flex;
    align-items: center;
    img {
      width: 32px;
      height: 32px;
    }
  }

  .footer-copyright {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 26px;
    letter-spacing: 5px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    cursor: pointer;
  }
  .record {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 26px;
    letter-spacing: 5px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    width: 100%;
    line-height: 100px;
    padding: 110px 0;

    .footer-logo {
      width: 460px;
      height: 110px;
      img {
        width: 460px;
        height: 110px;
      }
    }
    .footer-marginTop35 {
      margin-top: 70px;
    }
    .footer-marginTop8 {
      margin-top: 8px;
    }

    .footer-content {
      font-size: 32px;
      line-height: 52px;
      letter-spacing: 14px;
      img {
        width: 64px;
        height: 64px;
      }
    }

    .footer-copyright {
      font-weight: 400;
      font-size: 28px;
      line-height: 52px;
      letter-spacing: 10px;
    }

    .record {
      font-weight: 400;
      font-size: 28px;
      line-height: 52px;
      letter-spacing: 10px;
      text-decoration: none;
    }
  }
}
</style>
