<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
    <GoTop />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import GoTop from './components/GoTop.vue'
export default {
  name: 'App',
  components: {
    Header,
    Footer,
    GoTop
  }
}
</script>

<style lang="scss">
html {
  background: #f7f7f7;
}
body {
  margin: 0;
  min-width: 1200px;
  background: #fff;
}
</style>
